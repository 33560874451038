@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300..700&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300..700&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap");
/*scroll*/
::selection {
  background: #784fcf;
  color: #202125;
  text-shadow: none;
}
::-webkit-scrollbar {
  width: 5px;
  background-color: #784fcf;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
  display: none;
}
::-webkit-scrollbar-track-piece {
  background-color: #784fcf;
}
::-webkit-scrollbar-thumb:vertical {
  border-radius: 0px;
  height: 20px;
  background-image: linear-gradient(#784fcf, #784fcf);
}

html {
  scroll-behavior: smooth;
}
a {
  text-decoration: none !important;
}
a:hover {
  text-decoration: none !important;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
ul > li {
  font-family: Quicksand !important;
  color: var(--grey);
  font-weight: 300;
}

.t-center {
  text-align: center;
}
.t-left {
  text-align: left;
}
.t-right {
  text-align: right;
}
body {
  background: white !important;
}

.source {
  font-family: "Source Sans 3" !important;
}
.poppins {
  font-family: "poppins" !important;
}

/* colors */
.white {
  color: var(--white) !important;
}
.purple {
  color: var(--purple) !important;
}
.light-p {
  color: var(--light-p);
}
.green {
  color: var(--green);
}
.footer-bg {
  color: var(--footer-bg);
}
.black {
  color: var(--black);
}
.grey {
  color: var(--grey);
}
.termsText h1 {
  font-size: 30px;
  color: #000;
}
.termsText h1,.termsText h2,.termsText h3
{
  margin-bottom: 8px;
}
.termsText p {
  font-size: 16px;
  color: #111;
  line-height: 25px;
  font-weight: 400;
  margin-bottom: 8px;
}
.termsText li
{
  font-size: 16px;
  color: #111;
  line-height: 25px;
  font-weight: 400;
  margin-bottom: 8px;
}
/* Font size */
.fs-42 {
  font-size: 42px !important;
}
.fs-34 {
  font-size: 34px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-17 {
  font-size: 17px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-11 {
  font-size: 11px !important;
}

/* Font weight */
.light {
  font-weight: 400 !important;
}
.medium {
  font-weight: 500 !important;
}
.semi-bold {
  font-weight: 600 !important;
}
.bold {
  font-weight: 700 !important;
}

/* Padding */
.pt-10 {
  padding-top: 10px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-40 {
  padding-top: 40px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-40 {
  padding-bottom: 40px;
}
.p-0 {
  padding: 0;
}

/* Margin */
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.m-0 {
  margin: 0;
}
.m-auto {
  margin: auto;
}

/* Button */
.button {
  border-radius: 8px !important;
  text-align: center;
  font-family: Quicksand;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  text-transform: capitalize;
  padding: 0px 43px 0px 44px;
}
.purple-btn {
  background: #784fcf !important;
  color: #fff !important;
}

.logo {
  color: #000;
  font-family: Quicksand !important;
  font-size: 32px !important;
  font-style: normal;
  font-weight: 700 !important;
  text-transform: capitalize;
}
.header ul li a {
  color: #000;
  font-family: Quicksand;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
}
.header ul {
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  margin-right: 40px;
}
.header {
  background: #e9e5f4;
}
.hero-sec {
  background: #e9e5f4;
  position: relative;
}
.hero-sec .img-box {
  display: flex;
  justify-content: end;
}
.hero-sec .row {
  align-items: center;
}

.map-sec .search-box {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1.2px solid #959595;
  padding-inline: 15px;
}
.map-sec .search-box input {
  border: none;
}
.map-sec .search-box input:focus,
.map-sec .search-box input:focus-visible {
  border: none;
  outline: none;
  box-shadow: none;
}
.map-sec .search-box .chakra-icon {
  color: #959595;
}
.css-1ydjfm6[aria-checked="true"],
.css-1ydjfm6[data-checked] {
  background: var(--purple) !important;
  border-color: var(--purple) !important;
  color: var(--chakra-colors-white);
}
.about-sec {
  background: radial-gradient(
    50% 50% at 50% 50%,
    #784fcf 0%,
    rgb(120 79 207 / 90%) 100%
  );
  padding-block: 5em;
}
.acc-box h2 > button {
  border-radius: 10px;
  background: #e5f4ea;
  color: #000;
  font-family: Quicksand;
  font-size: 17px;
  font-weight: 600;
  text-transform: capitalize;
}
.acc-box h2 > button > span {
  color: black;
}

.acc-box h2 > button[aria-expanded="true"] {
  background: #784fcf;
  color: white;
  border-start-end-radius: 10px;
  border-start-start-radius: 10px;
  border-end-end-radius: 0;
  border-end-start-radius: 0;
}
.acc-box .chakra-collapse {
  background: rgb(120, 79, 207);
  border-start-end-radius: 0px;
  border-start-start-radius: 0px;
  border-end-end-radius: 10px;
  border-end-start-radius: 10px;
  color: rgb(255, 255, 255);
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
}
.acc-box h2 > button[aria-expanded="true"] > span {
  color: white;
}
.acc-box h2 > button:focus,
.acc-box h2 > button:focus-visible {
  outline: none;
  border: none;
  box-shadow: none;
}
.faqs-sec .img-box {
  display: flex;
  justify-content: end;
}
.contact-sec .detail-box > a {
  display: flex;
  align-items: center;
  gap: 5px;
}
.contact-sec .detail-box .social-icons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}
.contact-sec .form-box input,
.contact-sec .form-box textarea {
  border-radius: 8px;
  border: 1.5px solid #9c9992;
  width: 100%;
}
.contact-sec .form-box label {
  color: #9c9992;
  font-family: Quicksand;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
}

.contact-sec .row {
  align-items: baseline;
}
.contact-sec {
  background: #f2f2f2;
}
.footer {
  background: #784fcf;
  padding-block: 15px;
}
.hero-sec::before {
  content: "";
  position: absolute;
  background-image: url(../images/hero-b.png);
  background-repeat: no-repeat;
  background-size: auto;
  width: 10%;
  height: 30%;
  bottom: 9em;
}
.hero-sec::after {
  content: "";
  position: absolute;
  background-image: url(../images/hero-a.png);
  background-repeat: no-repeat;
  background-size: auto;
  width: 6%;
  height: 35%;
  top: 5em;
  right: 0;
}
.contact-sec {
  position: relative;
}
.contact-sec::before {
  content: "";
  position: absolute;
  background-image: url(../images/con-b.png);
  background-repeat: no-repeat;
  background-size: auto;
  width: 19%;
  height: 39%;
  bottom: 0;
  left: 0;
}
.contact-sec::after {
  content: "";
  position: absolute;
  background-image: url(../images/con-a.png);
  background-repeat: no-repeat;
  background-size: auto;
  width: 15%;
  height: 35%;
  bottom: 7em;
  left: 35em;
}

.privacy-sec {
  background: #e9e5f4;
}
.pp-row {
  align-items: flex-start !important;
}
/* Arham */

.applyForm label {
  color: #788094;
  font-size: 14px;
  transition: 0.3s;
}

.applyForm > div > div {
  margin: 0 0 20px 0 !important;
}

.applyForm > div {
  margin: 0 0 10px 0 !important;
}

.applyForm input,
.applyForm textarea,
.applyForm .react-international-phone-input-container,
.applyForm select {
  border: 1px solid #75767a;
  transition: 0.3s;
  border-radius: 12px;
  height: 52px;
}

.applyForm .react-international-phone-input-container {
  height: unset;
  padding: 6px 10px;
}

.applyForm .react-international-phone-country-selector button {
  border: none;
}

.applyForm > div > div:hover label {
  color: #784fcf;
}

.applyForm > div > div:hover input {
  border-color: #784fcf;
}
.applyForm > div > div:hover textarea {
  border-color: #784fcf;
}

.checkboxFamily > div > div {
  border: 1px solid #75767a;
  padding: 15px;
  border-radius: 12px;
}

.applyForm > div:hover label {
  color: #784fcf;
}

.applyForm > div:hover input {
  border-color: #784fcf;
}

.custom-marker {
  position: relative;
  display: inline-block;
  background-color: #f2f1f0;
  color: black;
  padding: 5px 10px;
  border-radius: 20px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  white-space: nowrap;
}

/* Downward green arrow */
.custom-marker .arrow {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #28a745; /* Green color */
  border-radius: 50%;
  position: absolute;
  left: -10px;
  top: -10px;
}

.custom-marker .arrow:before {
  content: "↓"; /* Downward arrow */
  position: absolute;
  left: 3px;
  top: 2px;
  color: white;
  font-size: 14px;
  font-weight: bold;
}

/* Speech bubble pointer */
.custom-marker:after {
  content: "";
  position: absolute;
  bottom: -21px;
  left: 50%;
  margin-left: -10px;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: #f2f1f0 transparent transparent transparent;
}
